import { merge } from 'lodash';
import React from 'react';
import Layout from 'src/components/layout/layout';
import FeedProductList from 'src/components/list/list-feed';
import SEO from 'src/components/misc/seo';
import Container from 'src/components/widget/container';
import PageHeader from 'src/components/widget/page-header';
import { categoryProductsQuery, productsQuery } from 'src/queries';
import { StaticContextType } from 'typings/custom';
import { ICategoryItem, ISubcategoryItem } from 'typings/generated/contentful';

interface Props {
  pageContext: {
    reactContext: StaticContextType;
    category: ICategoryItem & ISubcategoryItem;
  };
}

const SHOW_FILTER_THRESHOLD = 50;

const CategoryPage = (props: Props) => {
  const {
    pageContext: { category, reactContext },
  } = props;
  const {
    sys: { id },
    fields: { title, description },
  } = category;

  return (
    <Layout context={reactContext}>
      <SEO title={title} />
      <div className="flex flex-col items-center">
        <Container>
          <FeedProductList
            filter={(size) => size > SHOW_FILTER_THRESHOLD}
            giftLists={[]}
            query={merge({}, productsQuery(), categoryProductsQuery(id))}
            renderHeader={() => (
              <PageHeader description={description} title={title} />
            )}
          />
        </Container>
      </div>
    </Layout>
  );
};

export default CategoryPage;
